html, body, #app, #app>div {
  height: 100%;
} 

html {
  background-color: #000000;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif, 'Times New Roman', Times, serif, Geneva, Tahoma, sans-serif, 'Times New Roman', Times, serif;
}

#root {
  height: 100%;
}

.site-mobile {
  height: 100%;
  width: 100%;
  display: flex;
	flex-direction: column;
}

.site-desk {
  height: 100%;
  display: flex;
	flex-direction: column;
}

.site-content-mobile {
  /* display: flex; */
  width: 100%;
  flex: 1 0 auto;
  background-color: #ffffff;
  padding: var(--space) var(--space);
  /* height: 100vh; */
}


.site-content-desk {
  background-color: #ffffff;
  flex: 1 0 auto;
  /* flex: 1; */
}


.footer-desk {  
  flex: none;
  background-color: #4d4949;
}

.footer-mobile {  
  overflow: hidden !important;
  flex: none;
  background-color: #4d4949;
}


.header {
  display: flex;
  box-sizing: border-box;
  padding: 0.1em 0.1em 0.1em 0.1em;
  justify-content: center; 
  background: #ffffff;
  color: white;
}

.page-title {
  width: 500px;
  padding:20px 20px 20px 20px;
}

.page-title-mobile {
  justify-content: center; 
  align-items: center; 
}


/* Navbar */
.navbar {
  overflow: hidden;
  /* background-color: rgb(163, 55, 55); */
  background-color: #4d4949;
  text-align: justify;
  -ms-text-justify: distribute-all-lines;
  text-justify: distribute-all-lines;
}

.navbar_stretch {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}


.navbar_mobile {
  background-color: #4d4949;
  /* height: 8vh; */
}

.navbar_mobile-stretch {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.navbar_mobile-element-empty-box {
  /* flex-grow: 2; */
  width: 35%;
}

.navbar_tablet-element-empty-box {
  /* flex-grow: 2; */
  width: 60%;
}

.navbar_mobile-element-text {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-grow: 2; */
  width: 50%;
  height: 2rem;
  background-color: #7f7a7a;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 1rem 0rem 1rem 0rem;
}

.navbar_mobile-tablet-text {
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-grow: 2; */
  width: 25%;
  height: 2rem;
  background-color: #7f7a7a;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.6rem;
  padding: 1rem 1rem 1rem 1rem;
}

.navbar_mobile-element-bm-menu {
  /* flex-grow: 2; */
  width: 15%;
  background-color: #7f7a7a;
  position:relative;
}

.navbar_tablet-element-bm-menu {
  /* flex-grow: 2; */
  width: 15%;
  background-color: #7f7a7a;
  position:relative;
}

div.mobile-menu-wrap > div > div > .bm-burger-button  {
  height: 2rem;
  position: relative;
  background-color: #7f7a7a;
  margin-top: 1rem;
  margin-bottom: 1rem;
  /* margin-left: 1rem; */
  width: 80%;
  /* display: none; */
}

div.tablet-menu-wrap > div > div > .bm-burger-button {
  height: 2rem;
  position: relative;
  background-color: #7f7a7a;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  width: 55%;
}

.bm-burger-bars {
  background-color: #ffffff;
}

.bm-menu-wrap {
  position: absolute !important;
  background-color: #4d4949;
  width: 65vw !important;
  height: 28.6rem !important;
  top: 0;
  overflow: hidden !important;
}

div.tablet-menu-wrap > div > div.bm-menu-wrap {
  position: absolute !important;
  background-color: #4d4949;
  width: 42vw !important;
  height: 28.6rem !important;
  top: 0;
  overflow: hidden !important;
}

div.bm-menu {
  overflow: hidden !important;
}

div.bm-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
}

.bm-cross {
  width: 0.3rem !important;
  height: 2.2rem !important;
  /* margin-top: -0.3vh !important; */
  margin-left: -0.5rem !important;
  background: #ffffff;
}

div.tablet-menu-wrap > div > div.bm-menu-wrap > div > div.bm-cross-button > span > span.bm-cross{
  width: 0.3rem !important;
  height: 2.5rem !important;
  /* margin-top: 0.3vh !important; */
  margin-left: -0.5rem !important;
  background: #ffffff;
}

.bm-cross-button {
  width: 2.5rem !important;
  height: 2.8rem !important;
  /* margin-left: -3vh !important; */
  /* background-color: #cebe10; */
}

div.tablet-menu-wrap > div > div.bm-menu-wrap > div > div.bm-cross-button {
  width: 2.6rem !important;
  height: 3rem !important;
  /* background: #ff0000; */
}


.bm-item-list {
  margin-top: 4rem;
  margin-bottom: -4rem;
  width: 100%;
  overflow: hidden !important;
  /* display: flex */
}

bm-item {
  height: 4rem;
  border-bottom: 0.1rem solid !important;
  border-color: rgb(0, 0, 0) !important;
  background-color: #4d4949;
  display: flex !important;
  align-items: center !important;
  /* box-sizing: content-box; */
}

/* additional border above first child */
bm-item:first-child {
  border-top: 0.1rem solid !important;
} 

bm-item:has(a.active) {
  background-color: #7f7a7a;
}

div.tablet-menu-wrap > div > div.bm-menu-wrap > div.bm-menu > nav > bm-item:has(a.active) {
  background-color: #7f7a7a;
}

bm-item > a.active {
  background-color: #7f7a7a !important;
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem !important;
  width: 100% !important
}


div.tablet-menu-wrap > div > div.bm-menu-wrap > div.bm-menu > nav > bm-item > a.active {
  background-color: #7f7a7a !important;
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  /* font-size: 1.4rem !important; */
  width: 100% !important
}


a.sidebar-link{
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem !important;
  background-color: #4d4949;
  width: 100% !important;
}


/* Style the navigation bar links */
.navbar a {
  display: flex;
  justify-content: center; 
  align-items: center; 
  font-size: 2rem;
  color: rgb(255, 255, 255);
  padding: 1rem 1rem 1rem 1rem;
  text-decoration: none;
  flex: 1 1 0;
  white-space: nowrap
}

/* Right-aligned link */
.navbar a.right {
  float: right;
  border-style: solid;
}

/* Change color on hover */
.navbar a:hover {
  /* background-color: #4d4949; */
  background-color: #000000;
  color: rgb(255, 255, 255);
}

.navbar a.active{
  /* font-weight:bold; */
  background-color: #7f7a7a;
  color: rgb(255, 255, 255);
}

/* Column container */
.row {  
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
}

/* Footer */
.reviews {
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  background-color: rgb(163, 55, 55);
  color: white;
}

.footer-review {
  height: 13px;
  font-size: 1.3rem;;
  padding: 1vw 1vw 1vw 1vw;
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  background-color: #4d4949;;
  color: white;
}

.footer-review-mobile {
  height: 13px;
  font-size: 1rem;
  padding: 1vw 1vw 1vw 1vw;
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  align-items: center;
  background-color: rgb(163, 55, 55);
  color: white;
}

 
.page-container{
   font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
   text-align: center;
   font-size: 14pt;
   padding: 1vw 1vw 1vw 1vw;
 }

div.page-container-2{
  padding: 1vw 1vw 1vw 1vw;
}

.page-container-content{
  border:solid;
  display: flex;
  flex-direction: column;
 }

.page-container-wrapper{
  flex: 1;  /* 1 and it will fill whole space left if no flex value are set to other children*/
  background: gold;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;

}

.Loading{
  text-align: center;
  font-size: 15pt;
}

.list-group-reviews-item{
  color:black;
  text-align: left;
  /* font-size: 12pt; */
  margin-bottom: 6pt;
  outline:2px solid black;
  margin-bottom: 40px;
  padding: 0px
}

.pagination{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 0px;
}

button.page-reviews-item-mobile{
  cursor: pointer;
  color: #000000;
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  height: 45px;
  width: 50px;
}

button.page-reviews-item-desk{
  cursor: pointer;
  color: #000000;
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  height: 45px;
  width: 50px;
}

button.page-reviews-item-mobile:disabled{
  pointer-events: none;
  color:#8c8c8c
}

button.page-reviews-item-desk:disabled{
  pointer-events: none;
  color:#8c8c8c
}

button.page-reviews-item-desk:hover{
  background-color:#ffffff;
  color: #000000;
  cursor: pointer;
}

button.page-reviews-item-mobile-active{
  cursor: pointer;
  background-color: #4d4949;
  color: #fff;
  display: inline-block;
  border: none;
  font-weight: bold;
  margin: 0;
  padding: 0;
  height: 45px;
  width: 40px;
}

button.page-reviews-item-desk-active{
  cursor: pointer;
  background-color: #4d4949;
  color: #fff;
  display: inline-block;
  border: none;
  font-weight: bold;
  margin: 0;
  padding: 0;
  height: 45px;
  width: 40px;
}


button.page-reviews-item-arrow-mobile{
  display: inline-block;
  border: none;
  /* margin-right: 5;  */
  margin: 0;
  padding: 0;
  height: 35px;
  width: 40px;
}

button.page-reviews-item-arrow-desk{
  display: inline-block;
  border: none;
  /* margin-right: 5;  */
  margin: 0;
  padding: 0;
  height: 35px;
  width: 40px;
}


.navfooter-mobile{
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #ffffff;
  display: flex;
}


div.nav-footer-link-external-mobile{
  display: flex;
  color:#000000;
  justify-content: center; 
  align-items: center;
  margin-top: 5px;
}

a.nav-footer-link-mobile{
  padding-right: 3vw;
  line-height: 100%;
  color:#000000;
  text-decoration: underline;
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex: 1;
  flex-shrink: 0;
}

.navfooter-desk{
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: #ffffff;
  display: flex;
}



.navfooter-left-ragged-desk{
  padding: 0.5vw 0vw 0.5vw 2vw;
  background-color: #4d4949;
  background-color: #ffffff;;
  width: 55%;
  float:left;
}

.navfooter-right-ragged-desk{
  padding: 0.5vw 0vw 0.5vw 0vw;
  /* background-color:#4d4949; */
  background-color:#ffffff;
  width: 45%;
  float: right;
}

.navfooter a{
  background-color: #4d4949;
  color:#ffffff;
  font-size: 1vh;
  text-decoration: none;
  display: left;
  justify-content: left; 
  align-items: left; 
}


.navfooter-desk a{
  background-color: #ffffff;
  color:#000000;
  font-size: 1.0rem;
  text-decoration: none;
  display: left;
  justify-content: left; 
  align-items: left; 
}


.navfooter_stretch {
  width: 100%;
  height: 100%;
  line-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: wrap;
  /* justify-content: center; */
}


div.nav-footer-link-external{
  display: flex;
  padding-left: 50px;
  color:#000000;
  justify-content: left; 
  align-items: center;
}

a.nav-footer-mobile-link{
  display: flex;
  margin-left: 10px;
  color: #000000;
}

.navfooter-mobile-link-svg-desk{
  width:80px;
  background-color: #ffffff;
}


a.nav-footer-link-pages{
  padding-right: 3vw;
  line-height: 100%;
  color:#000000;
  text-decoration: underline;
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex: 1;
  flex-shrink: 0;
}

a.nav-footer-link-pages:hover{
  font-weight: normal !important;
}


.navfooter-desk a:hover{
  font-weight:bold;
}

path{
  stroke: #000000;
  stroke-width: 0.5px;
}

div.image-gallery-container > div > div> div > button > svg > path {
  stroke-width: 0.0px;
}
button.closeButton:hover{
  cursor: pointer;
}


div.ratingLine {
  overflow:auto;
  vertical-align: middle;
  height: auto;
  padding: 6px;
  padding-left: 20px;
  padding-right: 20px;
}

div.ratingLine-mobile {
  overflow:auto;
  vertical-align: middle;
  height: auto;
  padding: 3px;
  display: flex;
}

div.ratingLine-mobile-features {
  margin-left: 6px;
  height: 30px;
}

div.leftAligned {
  /* width:60%; */
  float: left;
}

div.rightAligned {
  float: right;
  text-align: right;
  white-space: nowrap;
  /* display: flex; */
  margin-left: 12px;
  margin-right: 6px;
  flex-grow: 1;
}

span.rightAligned {
  float: right;
}

span.leftAligned {
  width:60%;
  float: left;
}

div.ratingStarLine {
  height: auto;
  display: flex;
  width:94%;
  line-height: 30px;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

div.ratingStarLineComments {
  text-align: justify;
  text-justify: inter-word;
  overflow: hidden;
  height:auto;
  display: inline-block;
  width:95%;
/*   line-height: 120%; */
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}


div.ratingStarLineComments-mobile {
  text-align: left;
  text-justify: inter-word;
  overflow: hidden;
  height:auto;
  display: inline-block;
  width:95%;
/*   line-height: 120%; */
  align-items: center;
  margin-left: 6px;
  margin-bottom: 8px;
}

span.leftSide {
 width: 55%
}

span.rightSide {
  width: 45%;
/*   height: 30px; */
}

span.ratingDimension {
  display: inline-block;
  height: 30px;
  width: 9.5rem;
}

span.ratingDimension-mobile {
  display: inline-block;
  height: 30px;
  width: 10rem;
}

span.ratingDimensionStars {
  display: inline-block;
  width: 9rem;
}

span.stars {
  display: inline-block;
  line-height: 100%; 
  vertical-align: middle;
  height: 35px;
  width: 30px;
  margin-left: -5px;
}


svg.star {
  display: inline-block;
  margin: 0px;
  padding: 0px;
  height: 35px;
  width: 30px;
}

span.ratingDimensionNumber {
  display: inline-block;
  height: 30px;
  width: 25px;
  /* margin-left: -20px; */
}

span.features {
  padding-left: 20px;
  height: 30px;
  display: inline-block;
  
}

span.featureDimension {
  height: 30px;
  width: 250px;
  display: inline-block;
}

span.featureDimension-mobile {
  height: 30px;
  width: 280px;
  display: inline-block;
}

span.featureIcon {
/*   padding-left: 20px; */
  height: 30px;
  display: inline-block;
}

svg.iconsTrue {
  display: inline;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-bottom: 5px;
  width: 25px;
  height: 25px;
  color:green;
}

svg.iconsFalse {
  display: inline;
  vertical-align: middle;
  margin: 0px;
  padding: 0px;
  padding-top: 0px;
  padding-bottom: 6px;
  width: 23px;
  height: 23px;
  color:rgb(163, 55, 55);
}




div.paddingLine {
  height: 13px;
}

strong.Comments{
  display:inline-block;
  align-items: center;
/*   padding-right: 50px; */
  width: 45px;
}

strong.Overview{
  display:inline-block;
  align-items: center;
/*   padding-right: 50px; */
  width: 9.5rem;
}

h1.page-heading{
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 50px !important;
  margin-top: 30px !important;
}

h1.page-heading-vehicle{
  text-align: center;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 50px;
  margin-top: 30px;
  border-bottom: solid;
  padding-bottom: 5px;
}

div.reviewline-overview-desk{
  text-align: justify;
  margin-bottom: 20px;
}

div.reviewline-overview-mobile{
  text-align: left;
  margin-bottom: 20px;
}

span.ratingDimensionNumber-overview-gesamt {
  font-weight: bold;
  display: inline-block;
  vertical-align: middle;
}

div.reviewline-overview-single{
  margin-bottom: 12px;
  text-align: justify;
}

span.ratingDimension-overview-single{
  display: inline-block;
  /* line-height: 100%; */
  vertical-align: middle;
  width: 160px;
  height: 100%;
}

span.ratingDimensionStars-overview-single{
  margin-right:0px;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
}

span.stars-overview-single {
  display: inline-block;
  line-height: 100%; 
  vertical-align: middle;
  width:40px;
  height: 100%;
  margin-left: -10px;
}

svg.star-overview-single{
  display: block;
  margin: 0px;
  padding: 0px;
}

span.ratingDimensionNumber-overview-single {
  /* font-weight: bold; */
/*   margin-left: 40px; */
  display: inline-block;
  vertical-align: middle;
  /* font-size: 12pt; */
}

span.ratingDimension-overview-feature{
  display: inline-block;
  line-height: 100%;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

div.progressLine{
  font-size: 12pt;
  display: flex;
  align-items: center;
}

div.progressBar{
  display: inline-block;
  width: 80%;
  height: 8px;
  background-color: #000000;
  outline: 1px solid black;
}

div.statusBar{
  height: 8px;
  background-color: #7f7a7a;
}

/* colors::after
hellgrau: #7f7a7a;
dunkelgrau: #4d4949; */

div.statusBarValue{
  width: 10%;
  height: 25px;
  text-align: right;
  margin-left: 20px;
}

div.reviewline-overview-feature{
  display: flex;
  flex-direction: column;
  text-align: left;
  /* font-size: 12pt; */
  margin-bottom: 12px;
  width: 100%;
}

.vehicle-overview{
  text-align: left;
  margin-bottom: 30px;
}

.vehicle-listings{
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 0.7rem;
  text-align: right;
}

.list-group-vehicles{
  list-style-type: none;
  text-align: center;
  font-size: 14pt;
}

.list-group-vehicle-item-mobile{
  color:black;
  border-color: #000000;
  text-align: left;
  font-size: 12pt;
  margin-bottom: 6pt;
  outline:2px solid black;
  text-decoration: None;
  margin-bottom: 30px;
  padding: 0px
}

.list-group-vehicle-item-desktop{
  color:black;
  border-color: #000000;
  text-align: left;
  font-size: 12pt;
  margin-bottom: 6pt;
  outline:2px solid black;
  margin-bottom: 30px;
  padding: 0px;
  text-decoration: none;
}

.list-group-vehicle-item-headerline{
  font-weight: bold;
  color:white;
  outline:2px solid black;
  background-color: #4d4949;
  display: flex;
  /* padding: 10px 10px 10px 10px; */
  font-size: 12pt;
}

.list-group-vehicle-item-headerline-full-desk{
  padding: 10px 10px 10px 10px;
  width: 100%;
}

.list-group-vehicle-item-headerline-full-mobile{
  padding: 5px 5px 5px 5px;
  width: 100%;
  font-size: 10pt;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.list-group-vehicle-item-headerline-left-ragged-desk{
  padding: 10px 10px 10px 10px;
  width: 70%;
  float:left;
}

.list-group-vehicle-item-headerline-left-ragged-mobile{
  padding: 5px 5px 5px 5px;
  width: 70%;
  float:left;
  font-size: 10pt;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.list-group-vehicle-item-headerline-right-ragged-desk{
  width: 30%;
  float: right;
}

.list-group-vehicle-item-headerline-right-ragged-mobile{
  width: 30%;
  float: right;
}

.list-group-vehicle-item-headerline-stretch-mobile{
  width:100%;
  height:100%;
  display:flex;
  flex-flow:wrap;
  margin: 0 auto;
  justify-content: center;
  /* border: solid; */
}

.list-group-vehicle-item-headerline-stretch{
  width:100%;
  height:100%;
  display:flex;
  flex-flow:wrap;
  margin: 0 auto;
  justify-content: center;
}

.list-group-vehicle-item-headerline-element-recommend-mobile{
  /* padding: 10px 10px 10px 10px; */
  font-size: 10px;
  background-color: #7f7a7a;;
  color:#ffffff;
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 50%;
  outline:2px solid black;
}

.list-group-vehicle-item-headerline-element-recommend{
  padding: 10px 10px 10px 10px;
  font-size: 12px;
  background-color: #7f7a7a;;
  color:#ffffff;
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex: 1;
  flex-shrink: 0;
  outline:2px solid black;
}

.list-group-vehicle-item-headerline-element-new-mobile{
  /* padding: 10px 10px 10px 10px; */
  background-color: #7f7a7a;;
  font-size: 10px;
  color:#ffffff;
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 50%;
  outline:2px solid black;
}


.list-group-vehicle-item-headerline-element-new{
  padding: 10px 10px 10px 10px;
  background-color: #7f7a7a;;
  font-size: 12px;
  color:#ffffff;
  display: flex;
  justify-content: center; 
  align-items: center; 
  flex: 1;
  flex-shrink: 0;
  outline:2px solid black;
}

.list-group-vehicle-item-content-line{
  display: flex;
  padding: 10px 0px 10px 0px;
  width: 100%;
}

.list-group-vehicle-item-content-left-ragged-desk{
  margin-right: 10px;
  float:left;
}

.list-group-vehicle-item-content-right-ragged-desk{
  /* display: block; */
  display: flex;
  flex-direction: column;
  padding: 10px 10px 10px 10px;
  width: 100%;
}

.list-group-vehicle-item-content-right-ragged-mobile{
  /* display: block; */
  display: flex;
  flex-direction: column;
  padding: 3px 3px 3px 3px;
  width: 100%;
  overflow: hidden;
  /* max-width: 50%; */
}

.list-group-vehicle-item-content-right-ragged-desk-flex{
  display: flex;
  flex-direction: row;
  /* padding: 10px 10px 10px 10px; */
  width: 100%;
  height: 80%;
  float:right;
}

.list-group-vehicle-item-content-right-ragged-desk-flex-mobile{
  max-width: 100%;
  display: flex;
  /* overflow: hidden; */
}


div.price-mobile{
  font-size: 10pt;
  text-align: right;
  margin-right: 5px;
  width: 100%;
}

.list-group-vehicle-item-content-element-flexible{
  padding: 5px 5px 5px 5px;
  display: flex;
  /* flex: 0 0 120px; */
  width: 22%;
  flex-direction: row;
}

.list-group-vehicle-item-content-element-flexible-mobile{
  display: flex;
  width: 50%;
}


.list-group-vehicle-item-content-element-rightaligned{
  padding: 5px 5px 5px 5px;
  display: flex;
  /* flex: 0 0 130px; */
  width: 34%;
  flex-direction: column;
}


.list-group-vehicle-item-content-element-row-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.list-group-vehicle-item-content-element-row-container-mobile{
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.list-group-vehicle-item-content-element-flexible-mobile{
  display: flex;
  width: 60%;
}

.list-group-vehicle-item-content-element-rightaligned-mobile{
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  font-size: 12pt;
  overflow: hidden;
}

.list-group-vehicle-item-content-element-row{
  height: 100%;
  font-size: 12pt;
  overflow: hidden;
}


.list-group-vehicle-item-content-element-row-mobile{
  height: 100%;
  font-size: 10pt;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.list-group-vehicle-item-fuel-line{
  height: 30%;
  width: 100%;
  display: flex;
  font-size: 8pt;
  align-items: center;
}

.list-group-vehicle-item-fuel-line-mobile{
  height: 100%;
  /* margin-top: 20px; */
  width: 100%;
  display: flex;
  font-size: 10px;
  align-items: center;
}

.list-group-vehicle-item-fuel-line-inner{
  width: 60%;
}

.list-group-vehicle-item-fuel-line-inner-mobile{
  width: 0%;
}

.list-group-vehicle-item-fuel-line-right{
  width: 40%;
  text-align: right;
  font-weight: bold;
}

.list-group-vehicle-item-fuel-line-right-mobile{
  height: 100%;
  width: 100%;
  font-weight: bold;
  display: flex;
}

span.NavigateToDetails{
  display: inline-block;
  align-self: flex-end;
  width: 100%;
  text-align: right;
  /* height: 100%;
  display:inline-block;
  overflow:auto; */
}

div.thumpnail-desktop{
  justify-content: center; 
  align-items: center; 
  height: 180px;
  width: 240px;
}

div.thumpnail-mobile{
  justify-content: center; 
  align-items: center; 
  /* height: 120px;
  width: auto; */
}

img.thumpnail-desktop{
  height: 180px;
  width: 240px;
}

img.thumpnail-mobile{
  height: 6rem;
  width: auto;
}

div.overview-ratings-desk{
  display: flex;
  flex-direction: row;
  /* padding: 10px 10px 10px 10px; */
  width: 100%;
  padding-top: 15px;
}

div.overview-ratings-mobile{
  display: flex;
  flex-direction: row;
  /* padding: 10px 10px 10px 10px; */
  width: 100%;
  padding-top: 12px;
}

div.overview-ratings-left-ragged{
  display: flex;
  flex-direction: column;
  /* padding: 10px 10px 10px 10px; */
  width: 55%;
}

div.overview-ratings-right-ragged{
  display: flex;
  flex-direction: column;
  /* padding: 10px 10px 10px 10px; */
  width: 45%;
}

div.overview-ratings-mobile{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 12px;
}

div.vehicle-main-info{
  display: flex;
  width: 100%;
}

div.vehicle-main-info-mobile-first-line{
  display: flex;
  margin-top: 40px;
}

div.vehicle-main-info-mobile-content-line{
  font-size: 13pt;
  padding-bottom: 5px;
}

div.image-gallery-container{
  width: 50%;
  font-size: 25px;
  font-weight: bold;
  /* align-items: center; */
  /* margin: auto; */
  /* padding: 10px 10px 10px 10px; */
  /* width: 45%; */
}

div.vehicle-main-info-right-container{
  width: 50%;
  display: flex;
  margin-left: 25px;
  /* margin-top: 25px; */
}

div.vehilce-main-info-container-mobile{
  display: flex;
  margin-top: 12px;
}

div.vehilce-main-info-container-mobile-left-ragged{
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: left;
}

div.vehilce-main-info-container-mobile-right-ragged{
  display: flex;
  width: 50%;
  text-align: right;
}

div.vehicle-main-info-left-ragged{
  width: 50%;
  text-align: left;
}

div.vehicle-main-info-right-ragged{
  width: 50%;
  text-align: right;
}

div.vehicle-main-info-attribute{
  font-size: 13pt;
  padding-bottom: 5px;
}

div.technical-attributes-heading{
  padding-top: 25px;
  text-align: left;
  font-weight: bold;
  font-size: 16pt;
}

table.technical-attributes{
  width: 100%;
  font-size: 13pt;
}

tr.technical-attributes-table-line{
  width: 50%;

}

th.technical-attributes-table-cell{
  font-weight: normal;
  padding-bottom: 5px;
}

span.fuelConsumption{
  display: block;
  overflow: auto;
  padding: 1px 1px 5px 1px;
}

table.vehicleAttributeTable{
  border-collapse: collapse;
  font-size: 13pt;
  width:100%;
  /* padding-top: 40px;
  padding-bottom: 40px; */
}

tbody.vehicleAttributeTableBody{
  border-spacing: 0px;
  display: flex;
  flex-wrap: wrap;
  width:100%;
}

tr.vehicleAttributeRow{
  width: 25%;
  padding: 0; 
  margin-top: -2px;
}

th.vehicleAttributeCell{
  font-weight: normal;
}


ul.list-group-vehicles{
  padding-inline-start: 0;
  margin: 0;
}

ul.list-group-reviews-desk{
  padding-inline-start: 0;
  margin: 0;
  list-style-type: none;
  text-align: center;
}


ul.list-group-reviews-mobile{
  padding-inline-start: 0;
  margin: 0;
  list-style-type: none;
  text-align: center;
}

ul.vehicleAttributeList{
  padding-inline-start: 20px;
  margin: 0;
}

li.vehicleAttributeElement{
  list-style-position: outside;
  padding-top: 1px;
  padding-bottom: 5px; 
}

div.vehicleAdditionalAttributes{
  font-weight: bold;
}

ul.vehicleAdditionalAttributeList{
  padding-inline-start: 20px;
  font-weight: normal;
}

li.vehicleAdditionalAttributeElement{
  list-style-position: outside;
  padding-top: 1px;
  padding-bottom: 5px; 
}


@media screen  and (min-width:0px) and (max-width:900px) {
  tr.vehicleAttributeRow{width:100%;}
}

@media screen and (min-width:901px) and (max-width:2200px) {
  tr.vehicleAttributeRow{width:25%;}
}


div.vehicleDescriptionLine{
  line-height: 25px;
}

div.vehicle-all-attributes{
  text-align: left;
  font-size: 13pt;
  padding-top: 20px;
  padding-bottom: 5px;
  font-weight: normal;
}

div.gm-style{
  font-family: 'Segoe UI';
}

h2.infoWindow-heading {
  margin-top: 0pt;
  margin-bottom: 6pt;
  font-size: 1.1rem;
}

div.infoWindow-content-line {
  font-size: 1rem;
}

div.gm-style-iw-t > div{
  /* max-width: 16rem !important; */
  /* width: 30rem; */
  min-height: 12.5rem;  
  /* box-sizing: content-box !important; */
  /* text-align: left; */
  outline: none;
}


div.gm-style-iw-d > div{
  /* display: inline-block; */
  /* max-width: 9rem !important; */
  width: 10rem !important;
  height: 8.8rem !important;
  /* min-height: 9rem !important; */
  /* height: 20rem !important; */
  /* min-height: 11.5rem !important; */
  /* overflow: hidden; */
}

div.gm-style > div {
  border: none !important;
}


button.gm-ui-hover-effect{
  height: 2.4rem !important;
}

button.maps-button{
  margin-top: 6pt;
  /* background-color: #7f7a7a; */
  font-size: 0.9rem;
  cursor: pointer;
  /* background: none; */
  border: none;
  color: #0000EE;
}

div.contact-parent-flex-container{
  display: flex;
  flex-direction: row;
  width: 100%;
}

div.contact-flex-container-half{
  display: flex;
  flex-direction: column;
  /* padding: 10px 10px 10px 10px; */
  width: 100%;
}

div.contact-flex-container-half-right{
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  width: 100%;
}

div.contact-content-block-heading {
  font-weight: bold;
  padding-bottom: 5px;
}

div.contact-google-maps-replacement-container{
  display: flex;
  vertical-align: middle;
  align-items: center;
  border: solid;
  width: 90%;
  height: 100%;
  padding: 10px;
  margin: 10px
}

div.contact-google-maps-replacement-container-mobile{
  display: flex;
  vertical-align: middle;
  align-items: center;
  border: solid;
  width: 100%;
  height: 100%;
  /* padding: 10px; */
}

div.contact-google-maps-replacement-container-inner{
  /* width: 100%; */
  /* height: 100%; */
}

div.contact-google-maps-replacement-container-inner-mobile{
  padding: 10px
  /* height: 90%;  */
}

div.purchasing-content-block{
  margin-bottom: 30px;
  text-align: justify;
}

ul.purchasing-services{
  padding-inline-start: 0px;
  margin: 25px;
  margin-top: 15px;
}

li.purchasing-services{
  list-style-position: outside;
  padding-bottom: 4px; 
  font-weight: normal;
}

ul.vehicle-services{
  padding-inline-start: 0px;
  margin: 25px;
  margin-top: 15px;
}

li.vehicle-services{
  list-style-position: outside;
  padding-bottom: 4px; 
  font-weight: normal;
}

ul.vehicle-history{
  /* list-style-position: none; */
  margin: 0;
  margin-top: 15px;
}


li.vehicle-history{
  /* list-style-type: none; */
  padding-bottom: 4px; 
  font-weight: normal;
}

div.impressum-first-level-heading{
  font-weight: bold;
  text-align: left;
  font-size: 16pt;
  margin-bottom: 15px;
}

div.impressum-first-level-heading-mobile{
  font-weight: bold;
  text-align: left;
  font-size: 14pt;
  margin-bottom: 6px;
}

div.impressum-second-level-heading{
  font-style: italic;
  text-align: left;
  font-size: 14pt;
  margin-bottom: 15px;
}

div.impressum-second-level-heading-mobile{
  font-style: italic;
  text-align: left;
  font-size: 14pt;
  margin-bottom: 8px;
}

div.impressum-content-block{
  /* font-weight: bold; */
  text-align: justify;
  font-size: 14pt;
  margin-bottom: 15px;
}

div.impressum-content-block-mobile{
  /* font-weight: bold; */
  text-align: left;
  font-size: 14pt;
  margin-bottom: 15px;
}

div.impressum-content-block-tablet{
  /* font-weight: bold; */
  text-align: left;
  font-size: 14pt;
  margin-bottom: 15px;
}

ul.impressum-bullet-list{
  list-style: none;
  margin-left: 1em;
  padding-left: 1em;
}

ul.impressum-bullet-list > li:before {
  display: inline-block;
  content: "-";
  width: 1em;
  margin-left: -1em;
}

li.impressum-bullet-point{
margin-bottom: 8px;
}

/* react-image-galelery */
.image-gallery-icon{color:#fff;transition:all .3s ease-out;appearance:none;background-color:transparent;border:0;cursor:pointer;outline:none;position:absolute;z-index:4;filter:drop-shadow(0 2px 2px #1a1a1a)}@media(hover: hover)and (pointer: fine)
{.image-gallery-icon:hover{color:#aaaaaa}
.image-gallery-icon:hover .image-gallery-svg{transform:scale(1.1)}}
/* .image-gallery-icon:focus{outline:2px solid #aaaaaa} */
.image-gallery-using-mouse 
.image-gallery-icon:focus{outline:none}
.image-gallery-fullscreen-button,
.image-gallery-play-button{bottom:0;padding:20px}
.image-gallery-fullscreen-button 
.image-gallery-svg,
.image-gallery-play-button 
.image-gallery-svg{height:28px;width:28px}@media(max-width: 768px)
{.image-gallery-fullscreen-button,.image-gallery-play-button{padding:15px}
.image-gallery-fullscreen-button 
.image-gallery-svg,
.image-gallery-play-button 
.image-gallery-svg{height:24px;width:24px}}@media(max-width: 480px)
{.image-gallery-fullscreen-button,
.image-gallery-play-button{padding:10px}
.image-gallery-fullscreen-button 
.image-gallery-svg,
.image-gallery-play-button 
.image-gallery-svg{height:16px;width:16px}}
.image-gallery-fullscreen-button{right:0}
.image-gallery-play-button{left:0}
.image-gallery-left-nav,
.image-gallery-right-nav{padding:50px 10px;top:50%;transform:translateY(-50%)}
.image-gallery-left-nav 
.image-gallery-svg,
.image-gallery-right-nav 
.image-gallery-svg{height:120px;width:60px}@media(max-width: 768px)
{.image-gallery-left-nav 
.image-gallery-svg,
.image-gallery-right-nav 
.image-gallery-svg{height:72px;width:36px}}@media(max-width: 480px)
{.image-gallery-left-nav 
  .image-gallery-svg,
  .image-gallery-right-nav 
  .image-gallery-svg{height:48px;width:24px}}
  .image-gallery-left-nav[disabled],
  .image-gallery-right-nav[disabled]{cursor:disabled;opacity:.6;pointer-events:none}
  .image-gallery-left-nav{left:0}
  .image-gallery-right-nav{right:0}
  .image-gallery{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;-o-user-select:none;user-select:none;-webkit-tap-highlight-color:rgba(0,0,0,0);position:relative}
  .image-gallery.fullscreen-modal{background:#000;bottom:0;height:100%;left:0;position:fixed;right:0;top:0;width:100%;z-index:5}
  .image-gallery.fullscreen-modal 
  .image-gallery-content{top:50%;transform:translateY(-50%)}
  .image-gallery-content{position:relative;line-height:0;top:0}
  .image-gallery-content.fullscreen{background:#000}
  .image-gallery-content
  .image-gallery-slide 
  .image-gallery-image{max-height:calc(100vh - 80px)}
  .image-gallery-content
  .image-gallery-thumbnails-left 
  .image-gallery-slide 
  .image-gallery-image,
  .image-gallery-content
  .image-gallery-thumbnails-right 
  .image-gallery-slide 
  .image-gallery-image{max-height:100vh}
  .image-gallery-slide-wrapper{position:relative}
  .image-gallery-slide-wrapper
  .image-gallery-thumbnails-left,
  .image-gallery-slide-wrapper
  .image-gallery-thumbnails-right{display:inline-block;width:calc(100% - 110px)}@media(max-width: 768px)
  {.image-gallery-slide-wrapper
    .image-gallery-thumbnails-left,
    .image-gallery-slide-wrapper
    .image-gallery-thumbnails-right{width:calc(100% - 87px)}}
    .image-gallery-slide-wrapper
    .image-gallery-rtl{direction:rtl}
    .image-gallery-slides{line-height:0;overflow:hidden;position:relative;white-space:nowrap;text-align:center}
    .image-gallery-slide{left:0;position:absolute;top:0;width:100%}
    .image-gallery-slide.image-gallery-center{position:relative}
    .image-gallery-slide .image-gallery-image{width:100%;object-fit:contain}
    .image-gallery-slide .image-gallery-description{background:rgba(0,0,0,.4);bottom:70px;color:#fff;left:0;line-height:1;padding:10px 20px;position:absolute;white-space:normal}@media(max-width: 768px)
    {.image-gallery-slide .image-gallery-description{bottom:45px;font-size:.8em;padding:8px 15px}}
    .image-gallery-bullets{bottom:20px;left:0;margin:0 auto;position:absolute;right:0;width:80%;z-index:4}
    .image-gallery-bullets 
    .image-gallery-bullets-container{margin:0;padding:0;text-align:center}
    .image-gallery-bullets 
    .image-gallery-bullet{appearance:none;background-color:transparent;border:1px solid #fff;border-radius:50%;box-shadow:0 2px 2px #1a1a1a;cursor:pointer;display:inline-block;margin:0 5px;outline:none;padding:5px;transition:all .2s ease-out}@media(max-width: 768px)
    {.image-gallery-bullets 
      .image-gallery-bullet{margin:0 3px;padding:3px}
    }@media(max-width: 480px)
    {.image-gallery-bullets 
      .image-gallery-bullet{padding:2.7px}
    }
    .image-gallery-bullets 
    .image-gallery-bullet:focus{transform:scale(1.2);background:#337ab7;border:1px solid #337ab7}
    .image-gallery-bullets 
    .image-gallery-bullet.active{transform:scale(1.2);border:1px solid #fff;background:#fff}@media(hover: hover)and (pointer: fine)
    {.image-gallery-bullets 
      .image-gallery-bullet:hover{background:#337ab7;border:1px solid #337ab7}
      .image-gallery-bullets 
      .image-gallery-bullet.active:hover{background:#337ab7}}
      .image-gallery-thumbnails-wrapper{position:relative}
      .image-gallery-thumbnails-wrapper
      .thumbnails-swipe-horizontal{touch-action:pan-y}
      .image-gallery-thumbnails-wrapper.thumbnails-swipe-vertical{touch-action:pan-x}
      .image-gallery-thumbnails-wrapper.thumbnails-wrapper-rtl{direction:rtl}
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails-left,
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails-right{display:inline-block;vertical-align:top;width:100px}@media(max-width: 768px)
      {.image-gallery-thumbnails-wrapper
        .image-gallery-thumbnails-left,
        .image-gallery-thumbnails-wrapper
        .image-gallery-thumbnails-right{width:81px}
      }
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails-left 
      .image-gallery-thumbnails,
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails-right 
      .image-gallery-thumbnails{height:100%;width:100%;left:0;padding:0;position:absolute;top:0}
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails-left 
      .image-gallery-thumbnails 
      .image-gallery-thumbnail,
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails-right 
      .image-gallery-thumbnails 
      .image-gallery-thumbnail{display:block;margin-right:0;padding:0}
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails-left 
      .image-gallery-thumbnails 
      .image-gallery-thumbnail+
      .image-gallery-thumbnail,
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails-right 
      .image-gallery-thumbnails 
      .image-gallery-thumbnail+
      .image-gallery-thumbnail{margin-left:0;margin-top:2px}
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails-left,
      .image-gallery-thumbnails-wrapper
      .image-gallery-thumbnails-right{margin:0 5px}@media(max-width: 768px)
      {.image-gallery-thumbnails-wrapper
        .image-gallery-thumbnails-left,
        .image-gallery-thumbnails-wrapper
        .image-gallery-thumbnails-right{margin:0 3px}
      }
      .image-gallery-thumbnails{overflow:hidden;padding:5px 0}@media(max-width: 768px)
      {.image-gallery-thumbnails{padding:3px 0}}
      .image-gallery-thumbnails 
      .image-gallery-thumbnails-container{cursor:pointer;text-align:center;white-space:nowrap}
      .image-gallery-thumbnail{display:inline-block;border:4px solid transparent;transition:border .3s ease-out;width:100px;background:transparent;padding:0}@media(max-width: 768px)
      {.image-gallery-thumbnail{border:3px solid transparent;width:81px}}
      .image-gallery-thumbnail+
      .image-gallery-thumbnail{margin-left:2px}
      .image-gallery-thumbnail 
      .image-gallery-thumbnail-inner{display:block;position:relative}
      .image-gallery-thumbnail 
      .image-gallery-thumbnail-image{vertical-align:middle;width:100%;line-height:0}
      .image-gallery-thumbnail.active,
      .image-gallery-thumbnail:focus{outline:none;border:4px solid #4d4949}@media(max-width: 768px)
      {.image-gallery-thumbnail.active,
        .image-gallery-thumbnail:focus{border:3px solid #4d4949}}@media(hover: hover)and (pointer: fine)
        {.image-gallery-thumbnail:hover{outline:none;border:4px solid #4d4949}}@media(hover: hover)and (pointer: fine)and (max-width: 768px)
          {.image-gallery-thumbnail:hover{border:3px solid #337ab7}}
          .image-gallery-thumbnail-label{box-sizing:border-box;color:#fff;font-size:1em;left:0;line-height:1em;padding:5%;position:absolute;top:50%;text-shadow:0 2px 2px #1a1a1a;transform:translateY(-50%);white-space:normal;width:100%}@media(max-width: 768px)
          {.image-gallery-thumbnail-label{font-size:.8em;line-height:.8em}}
          .image-gallery-index{background:rgba(0,0,0,.4);color:#fff;line-height:1;font-size:1.3rem;padding:10px 20px;position:absolute;right:0;top:0;z-index:4}@media(max-width: 768px)
          {.image-gallery-index{font-size:.8em;padding:5px 10px}}


@media all and (min-width:0px) and (max-width:600px) {
  .page-container{ 
    margin-left:0.6rem;
    margin-right:0.6rem;
  }
}

@media all and (min-width:601px) and (max-width:800px) {
  .page-container{ 
    margin-left:5vw;
    margin-right:5vw;
  }
}
@media all and (min-width:801px) and (max-width:1000px) {
  .page-container{ 
    margin-left:5vw;
    margin-right:5vw;
  }
}
@media all and (min-width:1001px) and (max-width:1200px) {
  .page-container{ 
    margin-left:5vw;
    margin-right:5vw;
  }
}
@media all and (min-width:1201px) and (max-width:1400px) {
  .page-container{ 
    margin-left:8vw;
    margin-right:8vw;
  }
}
@media all and (min-width:1401px) and (max-width:1600px) {
  .page-container{ 
    margin-left:12vw;
    margin-right:12vw;
  }
}
@media all and (min-width:1601px) and (max-width:2000px) {
  .page-container{ 
    margin-left:17vw;
    margin-right:17vw;
  }
}
@media all and (min-width:2001px) and (max-width:2400px) {
  .page-container{ 
    margin-left:23vw;
    margin-right:23vw;
  }
}

@media all and (min-width:2401px) {
  .page-container{ 
    margin-left:25vw;
    margin-right:25vw;
  }
}


#cc-main {
  /** Change font **/
  --cc-font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;

  /** Change button primary color to black **/
  --cc-btn-primary-bg: #4d4949;
  --cc-btn-primary-border-color: #4d4949;
  --cc-btn-primary-hover-bg: #000000;
  --cc-btn-primary-hover-border-color: #000000;
  --cc-overlay-bg: none;

  /** Also make toggles the same color as the button **/
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);

  /** Make the buttons a bit rounder **/
  --cc-btn-border-radius: 10px;
  --cc-z-index: 2147483647;
}

button.cm__btn {
  /* pointer-events: none !important; */
  /* touch-action: none !important; */
}

div.cm__body{
  /* height: 1200px !important; */
}

/* :root:has(div.site-desk) p.cm__desc{
  min-height : 420px !important;
} */


:root:has(div.navfooter-desk) p.cm__desc{
  min-height : 420px !important;
}

:root:has(div.navfooter-mobile) p.cm__desc{
  min-height : 340px !important;
}


a.cookieButton > button {
  font-weight: 600 !important;
  /* color:#0000EE !important */
}

button.customCookieButton{
  font-size: .82em;
  font-weight: 600;
  min-height: 42px;
  min-width: 110px;
  padding: .5em 1.5em;
  text-align: center;
  color: var(--cc-btn-primary-color);
  background: #4d4949;
  border: 1px solid var(--cc-btn-primary-border-color);
  border-radius: var(--cc-btn-border-radius);
  margin-right: .375rem;
  cursor: pointer;
  /* background: var(--cc-btn-primary-hover-bg); */
  border-color: #000000;
  /* color: var(--cc-btn-primary-hover-color); */
}


/* colors:
stars: #f2b713
light grey: #7f7a7a;
dark grey: #4d4949;
black: #000000;
white: #ffffff; */

